import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ServiceData from './service.json';
import './Service.css';

const ServiceDetail = () => {
  
  const { serviceName } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    city: '',
    type_of_loan: serviceName,
    amount: '',
    profile: '',
    pin_code: '',
    state: ''
  });

  const [errors, setErrors] = useState({});
  const service = ServiceData[serviceName];
  const [activeDocument, setActiveDocument] = useState(null);

  const handleToggle = (documentType) => {
    setActiveDocument((prev) => (prev === documentType ? null : documentType));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Full Name is required";
    if (!formData.mobile) formErrors.mobile = "Phone number is required";
    else if (!/^\d{10}$/.test(formData.mobile)) formErrors.mobile = "Phone number must be 10 digits";
    if (!formData.city) formErrors.city = "City is required";
    if (!formData.amount) formErrors.amount = "Loan Amount is required";
    else if (isNaN(formData.amount) || formData.amount <= 0) formErrors.amount = "Loan Amount must be positive";
    if (!formData.profile) formErrors.profile = "Profile is required";
    if (!formData.pin_code) formErrors.pin_code = "PIN Code is required";
    if (!formData.state) formErrors.state = "State is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await fetch('https://growlotusfintech.in/growLotusMain/api/applyloan', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          alert('Form submitted successfully!');
          setFormData({
            name: '',
            mobile: '',
            city: '',
            type_of_loan: '',
            amount: '',
            profile: '',
            pin_code: '',
            state: ''
          });
          setErrors({});
        } else {
          alert('Failed to submit form. Please try again later.');
        }
      } catch (error) {
        alert('An error occurred. Please try again later.');
      }
    }
  };
  useEffect(()=>{
      window.scrollTo(0, 0);
    })

  return (
    <div className="p-4 sm:p-8 lg:p-[0_13%]">
      <div className="pt-20">
        {service ? (
          <>
            <div className="relative mb-6">
              <img
                src={service.HeaderImage}
                alt={service.title}
                className="w-full h-[250px] object-cover rounded-lg"
              />
              <p className="absolute inset-0 flex items-center justify-center text-white text-2xl font-bold uppercase">
                {service.title}
              </p>
            </div>

            {/* Main Content */}
            <div className="flex flex-col lg:flex-row gap-6 mb-10">
              <div className="bg-gray-900 text-white rounded-lg shadow-lg p-6 w-full lg:w-1/3">
                <p className="text-2xl text-yellow-400 text-center mb-4">Required Documents</p>
                {Object.keys(service.DocumentsRequired).map((type) => (
                  <div key={type} className="my-4">
                    <p
                      className={`cursor-pointer text-lg font-semibold border-b pb-2 ${
                        activeDocument === type ? "text-yellow-400" : "text-white"
                      }`}
                      onClick={() => handleToggle(type)}
                    >
                      {type}
                    </p>
                    <div
                      className={`overflow-hidden transition-all duration-500 ease-in-out ${
                        activeDocument === type ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                      }`}
                    >
                      <ul className="pl-4 list-disc space-y-1 text-sm">
                        {service.DocumentsRequired[type].map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full lg:w-2/3">
                <img
                  src={service.LoanImage}
                  alt={service.title}
                  className="w-full h-[300px] object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>

            {/* Loan Form */}
            <div className="loan-form-container flex flex-col md:flex-row gap-8">
              <div className="w-full md:w-1/2">
                <h2 className="text-2xl font-bold text-yellow-400">JOIN OUR TEAM AND GROW WITH US!</h2>
                <p>{service.CompanyTermConditions.Description1}</p>
                <p>{service.CompanyTermConditions.Description2}</p>
              </div>

              <div className="w-full md:w-1/2">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="flex flex-col md:flex-row gap-4">
                    <input
                      type="text"
                      id="name"
                      placeholder="Full Name"
                      value={formData.name}
                      onChange={handleChange}
                      className="border rounded-lg p-2 w-full"
                    />
                    <input
                      type="tel"
                      id="mobile"
                      placeholder="Phone Number"
                      value={formData.mobile}
                      onChange={handleChange}
                      className="border rounded-lg p-2 w-full"
                    />
                  </div>
                  <input
                    type="number"
                    id="amount"
                    placeholder="Loan Amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="border rounded-lg p-2 w-full"
                  />
                  <button type="submit" className="bg-yellow-400 text-black p-2 rounded-lg w-full">
                    Enquire Now
                  </button>
                </form>
              </div>
            </div>
          </>
        ) : (
          <h1 className="text-center text-2xl font-semibold">Service Not Found</h1>
        )}
      </div>
    </div>
  );
};

export default ServiceDetail;
