import React,{useState} from "react";
import "./Service.css";
import { service2 } from "../../assets/index.js";
import Form from '../Form/Form.js';

const PrivateFunding = () => {
    const [isShowForm, setForm] = useState(false);
    
      const isToggleForm = () => {
        setForm(!isShowForm);
      };
      const formdetails={
        fullName: '',
        phone: '',
        loantype: '',
      
      }
      function handlForm(){
        setForm(true);
    
      }
    return (
        <>
        <Form show={isShowForm} isToggleForm={isToggleForm} formRequierment={formdetails}   formIndex={2}/>
        <div className="service4" data-aos="">
        <div className="service-image">
                <img src={service2} alt="Finance Advice" />
            </div>
            <div className="service-content">
                <div className="services4">
                    <h2 className="service-title"> Private Funding</h2>
                    <p>
                    Private funding is a way for businesses to raise money for various purposes, such as startup costs, growth, research and development, and new equipment.         </p>
                </div>
                <button className="get-started-btn2" onClick={handlForm}>Check Now</button>
            </div>
           

        </div>
        </>
    );
};

export default PrivateFunding;
