import React, { useState, useEffect } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import "./SlickSlider.css";
import { GrowLotus3, GrowLotus1 } from "../../../assets/index.js"; // Ensure the correct path
import CustomForm from "../../navbar/CustomForm.jsx";

const SlickSlider = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [GrowLotus3, GrowLotus1, GrowLotus3, GrowLotus1]; // Ensure you have correct images

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds

    return () => {
      clearInterval(interval); // Cleanup on component unmount
    };
  }, []);

  return (
    <div className="carousel-container1 mt-[50px]">
      <div className="carousel">
        {/* Smooth Transition for Image */}
        <div
          className="carousel-image-wrapper"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: "transform 0.5s ease-in-out", // Smooth transition
            display: "flex",
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className="carousel-image"
              style={{ width: "100%", flexShrink: 0 }}
            />
          ))}
        </div>

        {/* Get Private Funding Button */}
        <button className="get-funding-button" onClick={openModal}>
          Get Private Funding
        </button>
        {isModalOpen && (
          <div className="private-model">
            <div className="funding-model" style={{ marginTop: "4px" }}>
              <CustomForm closeModal={closeModal} />
            </div>
          </div>
        )}
      </div>

      {/* Prev Button */}
      <button className="carousel-arrow prev-arrow" onClick={prevSlide}>
        <GoChevronLeft />
      </button>

      {/* Next Button */}
      <button className="carousel-arrow next-arrow" onClick={nextSlide}>
        <GoChevronRight />
      </button>

      {/* Dots Navigation */}
      <div className="dots-navigation mt-4">
        {images.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`dot ${currentIndex === index ? "active" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SlickSlider;
