import React, { useEffect, useState, useRef } from "react";
import "./Statistics.css";

const Statistics = () => {
  const [clientCount, setClientCount] = useState(0);
  const [activeClientCount, setActiveClientCount] = useState(0);
  const [yearCount, setYearCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false); // To control when counting starts
  const statRef = useRef(null); // Ref to the statistics container

  const startCounting = () => {
    let clientTarget = 10000;
    let activeClientTarget = 100;
    let yearTarget = 10;
    let employeeTarget = 50;

    const clientInterval = setInterval(() => {
      setClientCount((prev) => {
        if (prev >= clientTarget) {
          clearInterval(clientInterval);
          return clientTarget;
        }
        return prev + 100; // Increment by 50 for smoother animation
      });
    }, 20);

    const activeClientInterval = setInterval(() => {
      setActiveClientCount((prev) => {
        if (prev >= activeClientTarget) {
          clearInterval(activeClientInterval);
          return activeClientTarget;
        }
        return prev + 1;
      });
    }, 40);

    const yearInterval = setInterval(() => {
      setYearCount((prev) => {
        if (prev >= yearTarget) {
          clearInterval(yearInterval);
          return yearTarget;
        }
        return prev + 1;
      });
    }, 100);

    const employeeInterval = setInterval(() => {
      setEmployeeCount((prev) => {
        if (prev >= employeeTarget) {
          clearInterval(employeeInterval);
          return employeeTarget;
        }
        return prev + 1;
      });
    }, 100);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !isCounting) {
          setIsCounting(true);
          startCounting();
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (statRef.current) {
      observer.observe(statRef.current);
    }

    return () => {
      if (statRef.current) {
        observer.unobserve(statRef.current);
      }
    };
  }, [isCounting]);

  return (
    <div className="statistics" ref={statRef}>
      <div className="stat-item">
        <h3>{clientCount}+</h3>
        <p>Total Clients</p>
      </div>
      <div className="stat-item">
        <h3>{activeClientCount}+</h3>
        <p>Active Clients</p>
      </div>
      <div className="stat-item">
        <h3>{yearCount}+</h3>
        <p>Years of Business</p>
      </div>
      <div className="stat-item">
        <h3>{employeeCount}+</h3>
        <p>Employees</p>
      </div>
    </div>
  );
};

export default Statistics;
