import React, { useState, useEffect } from "react";
import './Navbar.css';
import { growLogo } from "../../assets/index.js";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import Form from '../Form/Form.js';
import CustomForm from "./CustomForm.jsx";

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowForm, setForm] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isAboutDropDownOpen, setAboutDropDownOpen] = useState(false);
  const [isBankServicesOpen, setBankServicesOpen] = useState(false);
  const [isOtherServicesOpen, setOtherServicesOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const toggleAboutDropdown = () => setAboutDropDownOpen(!isAboutDropDownOpen);
  const toggleBankServices = () => setBankServicesOpen(!isBankServicesOpen);
  const toggleOtherServices = () => setOtherServicesOpen(!isOtherServicesOpen);
  const handleForm = () => setForm(true);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const closeDropdowns = (e) => {
      if (!e.target.closest('.dropdown') && !e.target.closest('.about-dropdown-menu')) {
        setDropdownOpen(false);
        setAboutDropDownOpen(false);
        setBankServicesOpen(false);
        setOtherServicesOpen(false);
      }
    };
    document.addEventListener('click', closeDropdowns);
    return () => document.removeEventListener('click', closeDropdowns);
  }, []);
  useEffect(() => {
    const closeMenus = (e) => {
      if (!e.target.closest('.navbar')) {
        setIsMobileMenuOpen(false);
        setDropdownOpen(false);
        setAboutDropDownOpen(false);
        setBankServicesOpen(false);
        setOtherServicesOpen(false);
      }
    };

    document.addEventListener('click', closeMenus);
    return () => document.removeEventListener('click', closeMenus);
  }, []);


  return (
    <>
      <Form show={isShowForm} isToggleForm={() => setForm(!isShowForm)} formRequierment={{ fullName: '', phone: '', loantype: '' }} formIndex={2} />
      <nav className="navbar">
        <div className="navbar-brand">
          <NavLink to="/" onClick={toggleMobileMenu} className="flex items-center">
            <img src={growLogo} alt="logo" className="mr-2" />
            <div className="flex flex-col space-y-0">
              <p className=" text-sm  font-bold text-warning">Grow Lotus Fintech Pvt Ltd</p>
              <p className="site-description text-xs font-bold">
                लोन चाहिए  Grow Lotus आइए
              </p>
            </div>
          </NavLink>
        </div>


        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? (
            <span className="menu-icon" style={{ fontSize: "1.5rem" }}>✖</span>
          ) : (
            <span className="menu-icon" style={{ fontSize: "1.5rem" }}>☰</span>
          )}
        </div>

        <div className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`} >
          <NavLink to="/" onClick={toggleMobileMenu}>Home</NavLink>

          <div className="dropdown" onClick={toggleDropdown}>
            <Link className="dropdown-toggle max-sm:p-0" style={{ display: "flex", alignItems: "center", padding: "0" }}>
              <span>Services</span><RiArrowDropDownLine className="drop-down-btn" />
            </Link>
            {isDropdownOpen && (
              <div className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                <NavLink to='' onClick={toggleBankServices}>Bank & NBFC Services</NavLink>
                <NavLink to='' onClick={toggleOtherServices}>Other Services</NavLink>
              </div>
            )}
          </div>

          <div className="dropdown" onClick={toggleAboutDropdown}>
            <Link className="dropdown-toggle max-sm:p-0" style={{ display: "flex", alignItems: "center", padding: "0" }}>
              <span>About Us</span><RiArrowDropDownLine className="drop-down-btn" />
            </Link>
            {isAboutDropDownOpen && (
              <div className="about-dropdown-menu" onClick={(e) => e.stopPropagation()}>
                <NavLink to='/about?id=vision' onClick={() => {
                  toggleAboutDropdown();
                  toggleMobileMenu();
                }}>Our Vision</NavLink>
                <NavLink to='/about?id=mission' onClick={() => {
                  toggleAboutDropdown();
                  toggleMobileMenu();
                }}>Our Mission</NavLink>
                <NavLink to='/about?id=value' onClick={() => {
                  toggleAboutDropdown();
                  toggleMobileMenu();
                }}>Our Value</NavLink>
                <NavLink to='/about?id=md-msg' onClick={() => {
                  toggleAboutDropdown();
                  toggleMobileMenu();
                }}>MD Message</NavLink>
              </div>
            )}
          </div>

          {/* <NavLink to="/careers" onClick={toggleMobileMenu}>Careers</NavLink> */}
          <NavLink to="/contact" onClick={toggleMobileMenu}>Contact Us</NavLink>

          <div className="button-container">
            <a href="#" className="animated-button">
              <span></span><span></span><span></span><span></span>
              <button className="private-funding" onClick={openModal}>Private Funding</button>
            </a>
            <div className="applyButton">
              <button className="apply-btn" >IBC Login</button>
            </div>
          </div>


          {isModalOpen && (
            <div className="private-model">
              <div className="funding-model" style={{ marginTop: "4px" }}>
                <CustomForm closeModal={closeModal} />
              </div>
            </div>
          )}



          {isBankServicesOpen && (
            <div className="bank-dropdown-menu">
              <NavLink to='service/personal-loan' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>Personal Loan</NavLink>
              <NavLink to='' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>Credit Card</NavLink>
              <NavLink to='' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>Business Loan</NavLink>
              <NavLink to='' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>CC/OD/DOD</NavLink>
            </div>
          )}

          {isOtherServicesOpen && (
            <div className="bank-dropdown-menu ">
              <NavLink to='' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>Secured Term Loan</NavLink>
              <NavLink to='' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>Project Funding</NavLink>
              <NavLink to='' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>Equity Funding</NavLink>
              <NavLink to='' onClick={() => {
                toggleBankServices();
                toggleMobileMenu();
              }}>Emergency Funding</NavLink>
            </div>
          )}
        </div>
        {/* <div className="applyButton">
            <button className="apply-btn" >IBC Login</button>
          </div> */}
      </nav>
    </>
  );
};

export default Navbar;
