import React from "react";
import "./Gallery.css";
import { image1, image2, image3 } from "../../../assets/index.js";

const images = [image1, image2, image3, image1, image2, image3];

const Gallery = () => {
  return (
    <div>
      <h2 className="text-[2rem] text-center text-[#000] font-bold  uppercase pt-10">Gallery</h2>
      <div className="gal">
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Gallery Image ${index + 1}`}
            className="gallry"
          />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
