import React,{useState} from "react";
import "./Service.css";
import CoprateFunding from  "./CoprateFunding.jsx"
import PrivateFunding from "./PrivateFunding.jsx";
import UnsecureFunding from "./UnsecureFunding.jsx"
import { service1 , service2 } from "../../assets/index.js";
import gsap from 'gsap';

import Form from '../Form/Form.js';

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Service = () => {
  const [isShowForm, setForm] = useState(false);

  const isToggleForm = () => {
    setForm(!isShowForm);
  };
  const formdetails={
    fullName: '',
    phone: '',
    loantype: '',
  
  }
  function handlForm(){
    setForm(true);

  }
  return (
    <>
    <Form show={isShowForm} isToggleForm={isToggleForm} formRequierment={formdetails}   formIndex={2}/>
      <div className="mainServiceStart mt-[110px]" >
        <div className="service" >
          <div className="service-content services2">
            <h2 className="service-title ">Finance Advice</h2>
            <p>
            Grow Lotus Fintech Pvt Ltd offers expert, tailored financial advice to help you grow wealth, secure funding, and manage expenses.
              </p>
            <button className="get-started-btn" onClick={handlForm}>Get Started</button>
          </div>
          <div className="service-image">
            <img src={service1} alt="Finance Advice" />
          </div>
        </div>
        <div className="service2" >
        <div className="service-image">
            <img src={service2} alt="Finance Advice" />
          </div>
          <div className="service-content">
            <div className="services2" >
              <h2 className="service-title">Cibil Score</h2>
              <p>
                Understand Your Credit Health and Take Control of Your Financial
                Future
              </p>
            </div>
            <button className="get-started-btn2" onClick={handlForm}>Check Now</button>
          </div>
         
        </div>
        <CoprateFunding/>
        <PrivateFunding/>
        <UnsecureFunding/>
      </div>
     
    </>
  );
};

export default Service;
