import React, { useState } from 'react';
import './CustomForm.css';

const CustomForm = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '+91',
    email: '',
    city: '',
    amount: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
    alert('Form Submitted Successfully!');
    closeModal();
  };

  return (
    <div className="custom-form-container">
      <h2 className="custom-form-title">Private Funding</h2>
      <button
        className="custom-close-modal-fm"
        onClick={closeModal}
        aria-label="Close form"
      >
        ✖ {/* Close button */}
      </button>
      <form onSubmit={handleSubmit} className="custom-form-content">
        <label className="custom-form-label" htmlFor="name">
          Name
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
            className="custom-form-input"
            maxLength="50" // Limiting the name length
          />
        </label>

        <label className="custom-form-label" htmlFor="mobile">
          Mobile No
          <input
            type="text"
            name="mobile"
            id="mobile"
            value={formData.mobile}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
              handleChange({ target: { name: "mobile", value } }); // Update state with only digits
            }}
            placeholder="+91"
            required
            className="custom-form-input"
            maxLength="12" // Limiting the length of the mobile number
          />
        </label>


        <label className="custom-form-label" htmlFor="email">
          Email
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
            className="custom-form-input"
            maxLength="100" // Limiting email length
          />
        </label>

        <label className="custom-form-label" htmlFor="city">
          City
          <input
            type="text"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Enter your city"
            required
            className="custom-form-input"
            maxLength="50" // Limiting the city length
          />
        </label>

        <label className="custom-form-label" htmlFor="amount">
          Amount
          <select
            name="amount"
            id="amount"
            value={formData.amount}
            onChange={handleChange}
            required
            className="custom-form-select"
          >
            <option value="">Select Amount</option>
            <option value="below 2Cr">Below 2Cr</option>
            <option value="2 to 10 Cr">2 to 10 Cr</option>
            <option value="10 to 20Cr">10 to 20Cr</option>
            <option value="20 to 50Cr">20 to 50Cr</option>
          </select>
        </label>

        <button type="submit" className="custom-form-submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CustomForm;
