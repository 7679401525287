import React, { useState, useRef, useEffect } from "react";
import "./Banner.css";
// import { BannerImage } from "../../assets/index.js";
import gsap from "gsap";
import SplitType from "split-type";
import Form from "../Form/Form.js";

const Banner = () => {
  const heading = useRef();
  const [isShowForm, setForm] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    loantype: "",
  });

  useEffect(() => {
    const split = new SplitType(heading.current, { types: "chars" });
    gsap.from(split.chars, {
      y: 50,
      duration: 0.3,
      stagger: 0.05,
      opacity: 0,
      ease: "power2.out",
    });
  }, []);

  const isToggleForm = () => {
    setForm(!isShowForm);
  };

  const handleFormChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    // Add your form submission logic here
  };

  const handleFormButton = () => {
    setForm(true);
  };

  return (
    <>
      <Form show={isShowForm} isToggleForm={isToggleForm} formRequierment={formData} formIndex={2} />

      <div className="banner">
        <div className="banner-text">
          <h1 className="text-[#003c07]">
            Empowering <span className="highlight1" ref={heading}>Your <br /> Financial Future</span> with Tailored Solutions
          </h1>

          <p>
            At Grow Lotus Fintech Pvt Ltd, we provide personalized financial advice and
            innovative solutions to help you achieve your goals. With over 9
            years of experience, our expert team is dedicated to delivering
            seamless and effective financial services designed just for you.
          </p>
          <button className="explore-btn" onClick={handleFormButton}>Enquiry Now</button>
        </div>

        <div className="banner-image " data-aos="fade-left">
          <div className="banner-form-container">
            <form className="small-form" onSubmit={handleFormSubmit} >
              <div className="form-banner">
                <h4 className="enquiry-btn text-center">Enquiry</h4>
                <label htmlFor="fullName"> Name</label>
         
                <input
                  type="text"
                  id="fullName"
                  placeholder="Enter your full name"
                  // value={formData.fullName}
                  // onChange={handleFormChange}
                />
              </div>
              <div className="form-banner">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="Enter your phone number"
                  value={formData.phone}
                  onChange={handleFormChange}
                />
              </div>
              <div className="form-banner">
                <label htmlFor="loantype">Service Type</label>
                <select id="loantype" value={formData.loantype} onChange={handleFormChange}>
                  <option value="">Select Services</option>
                  <option value="home">Loan</option>
                  <option value="personal">Private</option>
                  <option value="business">Corporate</option>
                  <option value="unsecure">Unsecure</option>
                </select>
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
